import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/print',
        component: () => import('@/layout/PrintLayout.vue'),
        redirect: '/print/sprf',
        children: [
            {
                path: 'sprf/:filter?/:id',
                name: 'PrintSprfView',
                component: () => import('@/views/PrintSPRFView.vue'),
            },
            {
                path: 'performa-invoice/:id',
                name: 'PrintPerformanceInvoiceView',
                component: () => import('@/views/PrintPerformanceInvoiceView.vue'),
            },
            {
                path: 'invoice/:id',
                name: 'PrintInvoiceView',
                component: () => import('@/views/PrintInvoiceView.vue'),
            },
            {
                path: 'delivery-order/:id',
                name: 'PrintDeliveryOrderView',
                component: () => import('@/views/PrintDeliveryOrderView.vue'),
            },
            {
                path: 'end-user-data/:id',
                name: 'PrintEndUserDataView',
                component: () => import('@/views/PrintEndUserDataView.vue'),
            },
            {
                path: 'harga/:id/:type',
                name: 'PrintPriceView',
                component: () => import('@/views/PrintPriceView.vue'),
            },
            {
                path: 'quotation/:id',
                name: 'PrintQuotationView',
                component: () => import('@/views/PrintQuotationView.vue'),
            },
            {
                path: 'qrf/:id',
                name: 'PrintQRFView',
                component: () => import('@/views/PrintQRFView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
